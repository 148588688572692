import React from "react";
import vision2023Img from "@/app/blog/(posts)/vision_2023/renaissance.png";
import { ArticleMetadata } from "@/app_components/articles/ArticleMetadata";


export const blogVision2023: ArticleMetadata = {
    id: "blogVision2023",
    shortTitle: "Planning a Renaissance",
    fullTitle: "Planning a Renaissance - How we plan to grow the Royal Game of Ur",
    description: (
        "The Royal Game of Ur has been played for over 4500 years. We "
        + "want it to be played for another 4500 years! We plan to "
        + "achieve this by working to grow the community around the game."
    ),
    htmlDescription: <>
        In September of this year, I quit my job, moved to
        London, and began burning my savings so that I can
        work on this website, RoyalUr.net,
        full-time.
        {" "}
        <span className="font-italic">
            And I am loving it!
        </span>
    </>,
    path: "/blog/vision_2023",
    date: "30th November, 2023",
    datetime: "20231130T000000Z",
    authors: [{
        name: "Padraig Lamont",
        url: "https://padraiglamont.com",
    }],
    image: {
        src: vision2023Img,
        alt: "Two hands reaching out to touch the Royal Game of Ur.",
    },
    dir: [
        { label: "Home", href: "/" },
        { label: "Blog", href: "/blog" },
    ],
};
