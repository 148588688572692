import { LobbyPlayer } from "@/ts/business/lobby/LobbyPlayer";
import { APIPublicGamePreferences, defaultGamePreferences } from "@/ts/business/api/api_schema";
import { DisplayName } from "@/app_components/user/DisplayName";
import { Role } from "@/ts/business/role/Role";
import { HumanLobbyPlayer } from "@/ts/business/lobby/HumanLobbyPlayer";


interface LobbyPlayerDisplayNameProps {
    player: LobbyPlayer | null;
}


export function LobbyPlayerDisplayName({ player }: LobbyPlayerDisplayNameProps) {
    let name = "Anonymous";
    let preferences: APIPublicGamePreferences = defaultGamePreferences;
    let roles: Role[] = [];

    if (player) {
        name = player.getShortName();
        preferences = player.getPreferences() ?? defaultGamePreferences;
        if (player instanceof HumanLobbyPlayer) {
            roles = Role.list(player.getUserOrNull());
        }
    }

    return (
        <DisplayName
            name={name}
            preferences={preferences}
            roles={roles} />
    );
}
