import thumbnail from "./opengraph-image.png";
import { ArticleMetadata } from "@/app_components/articles/ArticleMetadata";


export const blogMusic2024: ArticleMetadata = {
    id: "blogMusic2024",
    fullTitle: "RoyalUr has a new soundtrack!",
    description: (
        "It's called Quest for the Royal Game, and it comes with its own music video. "
        + "We also have a Christmas tournament coming up!"
    ),
    path: "/blog/music_2024",
    date: "22nd November, 2024",
    datetime: "20241122T000000Z",
    authors: [{
        name: "Padraig Lamont",
        url: "https://padraiglamont.com",
    }],
    image: {
        src: thumbnail,
        alt: "The thumbnail of the music video for Quest for the Royal Game.",
    },
    dir: [
        { label: "Home", href: "/" },
        { label: "Blog", href: "/blog" },
    ],
};
