import styles from "./LiveLobbiesList.module.scss";
import { useLobbyManagerState } from "@/app_util/api/useLobbyManagerState";
import React, { startTransition, useState } from "react";
import { LobbySummary } from "@/ts/business/lobby/LobbySummary";
import { motion, AnimatePresence } from "framer-motion";
import { fadeInOut } from "@/app_util/fadeInOut";
import { cn } from "@/ts/util/cn";
import { GameType } from "@/ts/business/game/GameType";
import RoyalUrButton from "@/app_components/generic/buttons/RoyalUrButton";
import { PopupButton } from "@/app_components/generic/popup/PopupButton";
import { LobbyPlayerDisplayName } from "@/app_components/user/LobbyPlayerDisplayName";
import { Path } from "@/ts/util/Path";
import { GameClientControls } from "@/ts/business/GameClientControls";
import { BotType } from "@/ts/business/game/BotType";
import { GameMode } from "@/ts/business/game/GameMode";
import Image from "next/image";
import { getGameTypeIcon } from "@/app_components/icon/getGameTypeIcon";
import { HumanLobbyPlayer } from "@/ts/business/lobby/HumanLobbyPlayer";
import { KnownLobbySettings } from "@/ts/business/game/KnownLobbySettings";
import Link from "next/link";
import { LoginPrompt } from "@/app_components/prompt/LoginPrompt";
import { useOptionalUser } from "@/app_components/user/UserContext";


interface RuleSetPopupButtonProps {
    settings: GameType;
}


function RuleSetPopupButton({ settings }: RuleSetPopupButtonProps) {
    return (
        <PopupButton
            buttonClassName={styles.ruleset}
            buttonContent={(
                <>
                    <Image src={getGameTypeIcon(settings)} alt={settings.getDesc()} />
                    <p>
                        {settings.getName()}
                    </p>
                </>
            )}>

            <span className="font-bold">
                {`${settings.getName()} Rules: `}
            </span>
            <span>
                {settings.getDesc()}
            </span>
        </PopupButton>
    );
}


interface LiveLobbiesListEntryProps {
    summary: LobbySummary;
    getWatchLink: (lobbyID: string) => Path;
}


function LiveLobbiesEntry({ summary, getWatchLink }: LiveLobbiesListEntryProps) {
    return (
        <div className={styles.entry}>
            <RuleSetPopupButton settings={summary.settings} />

            <p className={styles.details}>
                <LobbyPlayerDisplayName player={summary.player1} />
                {" vs. "}
                <LobbyPlayerDisplayName player={summary.player2} />
            </p>

            <RoyalUrButton
                size="tiny"
                style="cta"
                href={getWatchLink(summary.lobbyID).toString()}>

                Watch
            </RoyalUrButton>
        </div>
    );
}


interface LiveLobbiesEntryListProps {
    summaries: LobbySummary[];
    getWatchLink: (lobbyID: string) => Path;
}


function LiveLobbiesEntryList({ summaries, getWatchLink }: LiveLobbiesEntryListProps) {
    const elems = [];
    for (const summary of summaries) {
        elems.push(
            <LiveLobbiesEntry
                key={summary.lobbyID}
                summary={summary}
                getWatchLink={getWatchLink} />,
        );
    }
    return (
        <>
            {elems}
        </>
    );
}


interface GameSettingsAppearances {
    finkelCount: number;
    blitzCount: number;
    mastersCount: number;
    othersCount: number;
    totalCount: number;
}


function countGamesBySettings(
    summaries: LobbySummary[],
): GameSettingsAppearances {
    let finkelCount = 0;
    let blitzCount = 0;
    let mastersCount = 0;
    let othersCount = 0;
    for (const summary of summaries) {
        if (summary.settings === GameType.FINKEL) {
            finkelCount += 1;
        } else if (summary.settings === GameType.BLITZ) {
            blitzCount += 1;
        } else if (summary.settings === GameType.MASTERS) {
            mastersCount += 1;
        } else {
            othersCount += 1;
        }
    }
    return {
        finkelCount,
        blitzCount,
        mastersCount,
        othersCount,
        totalCount: (
            finkelCount + blitzCount + mastersCount + othersCount
        ),
    };
}


function countPlayersBySettings(
    summaries: LobbySummary[],
): GameSettingsAppearances {
    let finkelCount = 0;
    let blitzCount = 0;
    let mastersCount = 0;
    let othersCount = 0;
    for (const summary of summaries) {
        const p1 = summary.player1;
        const p2 = summary.player2;
        const p1Human = (p1 === null || p1 instanceof HumanLobbyPlayer);
        const p2Human = (p2 === null || p2 instanceof HumanLobbyPlayer);
        const addCount = (p1Human ? 1 : 0) + (p2Human ? 1 : 0);
        if (summary.settings === GameType.FINKEL) {
            finkelCount += addCount;
        } else if (summary.settings === GameType.BLITZ) {
            blitzCount += addCount;
        } else if (summary.settings === GameType.MASTERS) {
            mastersCount += addCount;
        } else {
            othersCount += addCount;
        }
    }
    return {
        finkelCount,
        blitzCount,
        mastersCount,
        othersCount,
        totalCount: (
            finkelCount + blitzCount + mastersCount + othersCount
        ),
    };
}


interface PendingLobbyListEntryProps {
    gameType: GameType | null;
    waitingCount: number;
    playingCount: number;
    clientControls: GameClientControls;
}


function PendingLobbyListEntry({
    gameType, waitingCount, playingCount, clientControls,
}: PendingLobbyListEntryProps) {

    const user = useOptionalUser();

    let details = "";
    if (waitingCount > 0) {
        details += `${waitingCount}\xa0waiting`;
    }
    if (gameType === null || playingCount > 0) {
        if (details !== "") {
            details += ", ";
        }
        details += `${playingCount}\xa0playing`;
    }
    if (gameType === null) {
        details += " online";
    }

    const startGame = () => startTransition(() => {
        setClickedPlay(true);
        const playGameType = gameType ?? GameType.FINKEL;
        clientControls.startGame(new KnownLobbySettings(
            GameMode.ONLINE, playGameType, playGameType.getSettings(), BotType.HARD,
        ));
    });

    const [clickedPlay, setClickedPlay] = useState(false);
    const [showLoginPrompt, setShowLoginPrompt] = useState(false);

    return (
        <div className={cn(
            styles.entry,
            waitingCount > 0 && styles.waiting,
            gameType === null && styles.small,
        )}>
            {gameType && (
                <RuleSetPopupButton settings={gameType} />
            )}

            <p className={styles.details}>
                {details}
            </p>

            <RoyalUrButton
                size="tiny"
                style={gameType ? "cta" : "secondary"}
                onClick={() => {
                    if (user) {
                        startGame();
                    } else {
                        setShowLoginPrompt(true);
                    }
                }}>

                {(() => {
                    if (clickedPlay) {
                        if (clientControls.startingGame)
                            return "...";
                        if (clientControls.startGameError)
                            return "Error: " + clientControls.startGameError.message;
                    }
                    return (gameType ? "Play" : "Play Online");
                })()}
            </RoyalUrButton>

            {!user && (
                <>
                    <LoginPrompt
                        visible={showLoginPrompt}
                        close={() => setShowLoginPrompt(false)}
                        onNoThanks={() => startTransition(() => {
                            setShowLoginPrompt(false);
                            startGame();
                        })}>

                        <p>
                            Online games are better when you&apos;re not anonymous!
                        </p>
                    </LoginPrompt>
                </>
            )}
        </div>
    );
}


interface PendingLobbiesListProps {
    pending: LobbySummary[];
    started: LobbySummary[];
    clientControls: GameClientControls;
    small?: boolean;
}


function PendingLobbiesList({
    pending, started, clientControls, small,
}: PendingLobbiesListProps) {
    const waiting = countGamesBySettings(pending);
    const playing = countPlayersBySettings(started);
    return (
        <>
            {!small && (
                <>
                    <PendingLobbyListEntry
                        gameType={GameType.FINKEL}
                        waitingCount={waiting.finkelCount}
                        playingCount={playing.finkelCount}
                        clientControls={clientControls} />

                    <PendingLobbyListEntry
                        gameType={GameType.BLITZ}
                        waitingCount={waiting.blitzCount}
                        playingCount={playing.blitzCount}
                        clientControls={clientControls} />

                    <PendingLobbyListEntry
                        gameType={GameType.MASTERS}
                        waitingCount={waiting.mastersCount}
                        playingCount={playing.mastersCount}
                        clientControls={clientControls} />
                </>
            )}

            {small && (
                <PendingLobbyListEntry
                    gameType={null}
                    waitingCount={waiting.totalCount}
                    playingCount={playing.totalCount}
                    clientControls={clientControls} />
            )}

            <p className={cn(
                styles.create_link,
                small && styles.small,
            )}>
                <Link href={new Path(
                    "/game",
                    (
                        small
                            ? { mode: "friend", rules: "finkel", tab: "play" }
                            : { mode: "friend", tab: "rules" }
                    )
                ).toString()}>
                    Or, create a link to send to a friend!
                </Link>
            </p>
        </>
    );
}


interface LiveLobbiesListProps {
    className?: string;
    clientControls: GameClientControls;
    getWatchLink: (lobbyID: string) => Path;
    small?: boolean;
}


export function LiveLobbiesList({
    className, clientControls, getWatchLink, small,
}: LiveLobbiesListProps) {
    const [rawTab, setTab] = useState<"play" | "watch">("play");
    const tab = (small ? "play" : rawTab);

    const {
        lobbyManagerState,
        lobbyManagerStateLoading,
        lobbyManagerStateError,
    } = useLobbyManagerState();

    let statusElem = null;
    if (!lobbyManagerState || lobbyManagerStateLoading || lobbyManagerStateError) {
        let status = "Loading live games...";
        if (lobbyManagerStateError) {
            status = `Error loading live games: ${lobbyManagerStateError.message}`;
            console.error(status);
        }
        if (!small) {
            statusElem = (
                <p>{status}</p>
            );
        } else {
            statusElem = (<></>);
        }
    }

    const pending = lobbyManagerState?.pendingLobbies ?? [];
    const started = lobbyManagerState?.startedLobbies ?? [];

    return (
        <div className={className}>
            <motion.div
                className={styles.live_lobbies_container}
                {...fadeInOut()}>

                {!small && (
                    <>
                        <div className={styles.tabs}>
                            <button
                                className={cn(
                                    styles.tab,
                                    tab === "play" && styles.tab_active,
                                )}
                                onClick={() => setTab("play")}>

                                Play a game
                            </button>

                            <button
                                className={cn(
                                    styles.tab,
                                    tab === "watch" && styles.tab_active,
                                )}
                                onClick={() => setTab("watch")}>

                                Watch a game
                            </button>
                        </div>
                    </>
                )}

                <div>
                    <AnimatePresence mode="wait">
                        {statusElem && (
                            <motion.div
                                className={cn(
                                    styles.entry_list,
                                    small && styles.small,
                                )}
                                {...fadeInOut({ delay: lobbyManagerStateLoading ? 0.25 : 0.0 })}>

                                {statusElem}
                            </motion.div>
                        )}

                        {!statusElem && tab === "play" && (
                            <motion.div
                                key="play"
                                className={cn(
                                    styles.entry_list,
                                    small && styles.small,
                                )}
                                {...fadeInOut()}>

                                <PendingLobbiesList
                                    pending={pending}
                                    started={started}
                                    clientControls={clientControls}
                                    small={small} />
                            </motion.div>
                        )}

                        {!statusElem && tab === "watch" && (
                            <motion.div
                                key="watch"
                                className={styles.entry_list}
                                {...fadeInOut()}>

                                {started.length > 0 && (
                                    <LiveLobbiesEntryList
                                        summaries={started}
                                        getWatchLink={getWatchLink} />
                                )}

                                {started.length === 0 && (
                                    <p className="padding-sm">
                                        The boards are quiet right now
                                    </p>
                                )}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </motion.div>
        </div>
    );
}
