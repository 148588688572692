import styles from "./HomeBackground.module.scss";
import React, { ReactNode } from "react";
import { cn } from "@/ts/util/cn";
import homeBackgroundImg from "@/res/backgrounds/home.png";


interface HomeBackgroundProps {
    className?: string;
    children?: ReactNode;
    fullscreen?: boolean;
}


export function HomeBackground({ className, children, fullscreen }: HomeBackgroundProps) {
    return (
        <section className={cn(
            className,
            styles.container,
            fullscreen && styles.fullscreen,
        )}>
            <div
                className={styles.background}
                style={{ backgroundImage: `url(${homeBackgroundImg.src})` }} />

            {children}
        </section>
    );
}
