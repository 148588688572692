import thumbnail from "./opengraph-image.png";
import { ArticleMetadata } from "@/app_components/articles/ArticleMetadata";


export const blogReview2024: ArticleMetadata = {
    id: "blogReview2024",
    shortTitle: "Advance from playur to pro with Game Review",
    fullTitle: "Advance from playur to pro with Game Review 🐼",
    description: (
        "Learn what wild risks are worth taking! Game review highlights "
        + "moves from your game for you to learn from, laying the path "
        + "for you to become a pro."
    ),
    path: "/blog/review_2024",
    date: "18th October, 2024",
    datetime: "20241018T000000Z",
    authors: [{
        name: "Padraig Lamont",
        url: "https://padraiglamont.com",
    }],
    image: {
        src: thumbnail,
        alt: "A screenshot from a game review.",
    },
    dir: [
        { label: "Home", href: "/" },
        { label: "Blog", href: "/blog" },
    ],
};
