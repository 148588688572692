import thumbnail from "./opengraph-image.png";
import { ArticleMetadata } from "@/app_components/articles/ArticleMetadata";


export const blogHalloween2024: ArticleMetadata = {
    id: "blogHalloween2024",
    fullTitle: "The making of our new Halloween theme 🎃",
    description: (
        "It doesn't get spooki-ur than this! Read about our new Halloween board, made by Alcoth."
    ),
    htmlDescription: (<>
        It doesn&apos;t get
        {" "}
        <span className="font-italic">
            spooki-ur
        </span>
        {" "}
        than this! Read about our new Halloween board, made by Alcoth.
    </>),
    path: "/blog/halloween_2024",
    date: "25th October, 2024",
    datetime: "20241025T000000Z",
    authors: [{
        name: "Padraig Lamont",
        url: "https://padraiglamont.com",
    }],
    image: {
        src: thumbnail,
        alt: "A screenshot of Alcoth's finished Halloween theme.",
    },
    dir: [
        { label: "Home", href: "/" },
        { label: "Blog", href: "/blog" },
    ],
};
