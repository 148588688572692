import React from "react";
import masters2024Img from "./three_dice.png";
import { ArticleMetadata } from "@/app_components/articles/ArticleMetadata";


export const blogMasters2024: ArticleMetadata = {
    id: "blogMasters2024",
    shortTitle: "We updated Masters, and custom games are now free!",
    fullTitle: "We updated Masters, and custom games are now free!",
    description: (
        "The Masters ruleset is now more historically accurate, "
        + "and everyone can now start games with custom rules."
    ),
    htmlDescription: <>
        We have updated the Masters ruleset to be more historically accurate,
        and anyone with an account can now make games with custom rules.
    </>,
    path: "/blog/masters_2024",
    date: "9th September, 2024",
    datetime: "20240909T000000Z",
    authors: [{
        name: "Padraig Lamont",
        url: "https://padraiglamont.com",
    }],
    image: {
        src: masters2024Img,
        alt: "A screenshot of the three dice that are now used in Masters",
    },
    dir: [
        { label: "Home", href: "/" },
        { label: "Blog", href: "/blog" },
    ],
};
