import React from "react";
import accounts2024Img from "./name.png";
import { ArticleMetadata } from "@/app_components/articles/ArticleMetadata";


export const blogAccounts2024: ArticleMetadata = {
    id: "blogAccounts2024",
    shortTitle: "Accounts have arrived!",
    fullTitle: "Accounts have arrived!",
    description: "I'm ecstatic to announce that accounts have finally made it to RoyalUr.net!",
    htmlDescription: <>
        I&apos;m ecstatic to announce that accounts have finally
        made it to RoyalUr.net!
    </>,
    path: "/blog/accounts_2024",
    date: "11th March, 2024",
    datetime: "20240311T000000Z",
    authors: [{
        name: "Padraig Lamont",
        url: "https://padraiglamont.com",
    }],
    image: {
        src: accounts2024Img,
        alt: "A screenshot of the new account page!",
    },
    dir: [
        { label: "Home", href: "/" },
        { label: "Blog", href: "/blog" },
    ],
};
