import React from "react";
import timerImg from "./timer.png";
import { ArticleMetadata } from "@/app_components/articles/ArticleMetadata";


export const blogTiming2024: ArticleMetadata = {
    id: "blogTiming2024",
    shortTitle: "Timing, resigning and rule customising",
    fullTitle: "Timing, resigning and rule customising! 🎨",
    description: "Games are now timed, you can now resign from games, and memburs can now create games with custom rules!",
    htmlDescription: <>
        Games are now timed, you can now resign from games,
        and memburs can now create games with custom rules!
        {" "}
        <span className="font-italic">
            Read here about how the new timing works.
        </span>
    </>,
    path: "/blog/timing_2024",
    date: "2nd July, 2024",
    datetime: "20240702T000000Z",
    authors: [{
        name: "Padraig Lamont",
        url: "https://padraiglamont.com",
    }],
    image: {
        src: timerImg,
        alt: "A screenshot of the timer running out",
    },
    dir: [
        { label: "Home", href: "/" },
        { label: "Blog", href: "/blog" },
    ],
};
