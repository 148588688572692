import styles from "./RoyalModal.module.scss";
import { Modal } from "@/app_components/generic/popup/Modal";
import { ReactNode } from "react";
import { cn } from "@/ts/util/cn";
import { RoyalCard } from "@/app_components/generic/card/themed/RoyalCard";


interface RoyalModalProps {
    children?: ReactNode;
    visible: boolean;
    close: () => void;

    containerClassName?: string;
    contentsClassName?: string;
}


export function RoyalModal({
    children, visible, close,
    containerClassName, contentsClassName,
}: RoyalModalProps) {
    return (
        <Modal
            visible={visible}
            close={close}
            fixed={true}
            containerClassName={cn(containerClassName, styles.container)}
            contentsClassName={cn(contentsClassName, styles.contents)}>

            <RoyalCard>
                {children}
            </RoyalCard>
        </Modal>
    );
}
