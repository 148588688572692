import React from "react";
import Link from "next/link";
import styles from "./ArticleList.module.scss";
import Image from "next/image";
import { ArticleMetadata } from "@/app_components/articles/ArticleMetadata";
import { cn } from "@/ts/util/cn";


type RequiredMetadataFields = "fullTitle" | "path";
type RequiredArticleMetadata = Pick<Required<ArticleMetadata>, RequiredMetadataFields>;
type OptionalArticleMetadata = Exclude<Partial<ArticleMetadata>, RequiredMetadataFields>;
type ListArticleMetadata = RequiredArticleMetadata & OptionalArticleMetadata;


interface ArticleListProps {
    articles: ListArticleMetadata[];
    exclude?: ListArticleMetadata[];
    compact?: boolean;
}


export function ArticleList({ articles, exclude, compact }: ArticleListProps) {
    compact ??= false;

    const articleElems = [];
    for (const article of articles) {
        if (exclude && exclude.includes(article))
            continue;

        const title = article.shortTitle ?? article.fullTitle;
        articleElems.push(
            <div
                key={title.replaceAll(/[^a-zA-Z]/g, "")}
                className={cn(
                    styles.article_link,
                    !compact && styles.not_compact,
                )}>

                <Link href={article.path} className={styles.article_image_link}>
                    {article.image && <Image src={article.image.src} alt={article.image.alt} />}
                </Link>
                <div className={styles.article_link_content}>
                    <Link href={article.path}>
                        <h3>{title}</h3>
                    </Link>
                    {article.date && (
                        <p className={styles.article_date}>
                            {article.date}
                        </p>
                    )}
                    <p>
                        {article.htmlDescription ?? article.description}
                        {" "}
                        <Link href={article.path}>
                            Read more...
                        </Link>
                    </p>
                </div>
            </div>,
        );
    }
    return (
        <div className={styles.articles}>
            {articleElems}
        </div>
    );
}
