import { useQuery } from "@tanstack/react-query";
import { webGameAPI } from "@/app_util/WebGameAPI";
import { LobbyManagerState } from "@/ts/business/api/GameAPI";


export function useLobbyManagerState(): {
    lobbyManagerState: LobbyManagerState | null | undefined;
    lobbyManagerStateLoading: boolean;
    lobbyManagerStateError: Error | null;
} {
    const { data, isLoading, error } = useQuery({
        queryKey: ["lobbyManagerState"],
        queryFn: () => webGameAPI.getLobbyManagerState(),
        staleTime: 3 * 1000,
        refetchInterval: 3 * 1000,
    });
    return {
        lobbyManagerState: data,
        lobbyManagerStateLoading: isLoading,
        lobbyManagerStateError: error,
    };
}
