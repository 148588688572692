import styles from "./PromptPopup.module.scss";
import { ReactNode } from "react";
import { RoyalModal } from "@/app_components/generic/popup/themed/RoyalModal";


interface PromptPopupProps {
    children?: ReactNode;
    title?: string;
    visible: boolean;
    close: () => void;
}


export function PromptPopup({ children, title, visible, close }: PromptPopupProps) {
    return (
        <RoyalModal
            containerClassName={styles.prompt_popup}
            contentsClassName={styles.prompt_popup_content}
            visible={visible}
            close={close}>

            <h2>
                {title}
            </h2>
            {children}
        </RoyalModal>
    );
}
