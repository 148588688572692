import React from "react";
import panda2024Img from "./panda.png";
import { ArticleMetadata } from "@/app_components/articles/ArticleMetadata";


export const blogPanda2024: ArticleMetadata = {
    id: "blogPanda2024",
    shortTitle: "The Panda's training montage is complete!",
    fullTitle: "The Panda's training montage is complete!",
    description: (
        "One month ago, the Panda left on an epic training montage. "
        + "Now they're back and stronger than ever!"
    ),
    htmlDescription: <>
        One month ago, the Panda left on an epic training montage.
        {" "}
        <span className="font-italic">
            Now they&apos;re back and stronger than ever!
        </span>
    </>,
    path: "/blog/panda_2024",
    date: "11th May, 2024",
    datetime: "20240511T000000Z",
    authors: [{
        name: "Padraig Lamont",
        url: "https://padraiglamont.com",
    }],
    image: {
        src: panda2024Img,
        alt: "A screenshot of selecting the Panda as your opponent",
    },
    dir: [
        { label: "Home", href: "/" },
        { label: "Blog", href: "/blog" },
    ],
};
