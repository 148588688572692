import holidays2023Img from "@/app/blog/(posts)/holidays_2023/holiday_theme_banner.png";
import React from "react";

import { ArticleMetadata } from "@/app_components/articles/ArticleMetadata";


export const blogHolidays2023: ArticleMetadata = {
    id: "blogHolidays2023",
    shortTitle: "Happy Holidays!",
    fullTitle: "Happy Holidays!",
    description: (
        "Happy holidays everyone! In celebration of this holiday season, "
        + "we have released a limited-time holiday theme to RoyalUr.net!"
    ),
    htmlDescription: <>
        Happy holidays everyone! 🎄In celebration of this holiday season,
        we have released a limited-time holiday theme to RoyalUr.net!
    </>,
    path: "/blog/holidays_2023",
    date: "15th December, 2023",
    datetime: "20231215T000000Z",
    authors: [{
        name: "Padraig Lamont",
        url: "https://padraiglamont.com",
    }],
    image: {
        src: holidays2023Img,
        alt: "A screenshot of the new holidays theme!",
    },
    dir: [
        { label: "Home", href: "/" },
        { label: "Blog", href: "/blog" },
    ],
};
